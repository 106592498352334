<template>
  <footer class="footer" id="contact">
      <div class="footer_top" id="footerTop">
        <h3 class="main_heading_footer">DO YOU HAVE A PROJECT THAT <br> NEEDS SOME LOVE?</h3>
        <div class="contact_block__container">
          <div class="contact_block">
                <p >Fill in the form below,<br class="nob__class">or wave at <a href="mailto:beauty@digitalbeautywork.com">beauty@digitalbeautywork.com</a><br>
                  We’ll get back to you right away.</p>

        <form @submit.prevent="submitMessage" novalidate autocomplete="off">
          <p class="form__text">To contact us, please enter your<br> details below.</p>
          <p class="input__placeholder">Email address</p>

          <div class="input_wr input__wr1">
            <input type="email"
                   v-model.trim="form.email"
                   required
                   name="email"
                   autocapitalize="off"
                   autocomplete="off"
                   autocorrect="off"
                   placeholder="Email Address">
          </div>
          <p class="input__placeholder">Project</p>

          <div class="input_wr input__wr2">
            <input type="text"
                   name="message"
                   v-model.trim="form.message"
                   autocapitalize="off"
                   autocomplete="off"
                   autocorrect="off"
                   placeholder=" A few words about your project (optional)">
          </div>
          <p class="form_error" v-if="formError">Please fill all fields</p>
          <p class="form_success" v-if="formSuccess">Your message submitted successfully!</p>

          <button type="submit" class="red_btn">Let's Talk!</button>

        </form>
        </div>
        </div>
      </div>

      <div class="footer_bottom">
        <header-for-footer/>


        <div class="container__social">
          <div class="year__container">
            <p>&#169;{{currentYear}}— DIGITAL BEAUTY WORK</p>
          </div>

          <div class="footer__social">
            <a href="https://www.instagram.com/digitalbeautywork/"><img class="footer__social__links" src="@/assets/img/instagramm.png" alt="instagram"></a>
            <a href="https://www.linkedin.com/company/digital-beauty-work/"><img class="footer__social__links" src="@/assets/img/linkidin.png" alt="linkedin"></a>
            <a href="https://www.facebook.com/digitalbeautywork"><img class="footer__social__links__facebook" src="@/assets/img/facebook.png" alt="facebook"></a>
          </div>

          <div class="privaty__police">
            <router-link to="privacy-policy">Privacy Policy</router-link>
            <span>–</span>
            <router-link to="terms-conditions">Terms & Conditions</router-link>
          </div>


        </div>

      </div>
  </footer>
</template>

<script>

import axios from 'axios'
/*
import HeaderForFooter from "./HeaderForFooter";
*/
const HeaderForFooter = () => import('./HeaderForFooter');

export default {
  name: 'Footer',
  components: {HeaderForFooter},

  data() {
    return {
      currentYear: new Date().getFullYear(),
      formError: false,
      formSuccess: false,
      form: {
        email: '',
        message: ''
      }
    };
  },
  methods: {
    submitMessage() {
      axios.post('https://back.digitalbeautywork.com/api/feedback', {
        email: this.form.email,
        message: this.form.message
      })
        .then(response => {
          this.form = response.data
          this.formError = false
          this.formSuccess = true
        })
        .catch(error => {
          if (error.response.status === 422 || error.response.status === 400) {
            this.formError = true
          }
        })
    }
  }
}
</script>

<style scoped lang="scss"></style>
